import Config from "@/vendor/config"
import Request from "@/api/request.js"

const goodsList = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/goods/list',params)
}

const goodsDetail = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/goods/detail',params)
}

const goodsExchange = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/goods/exchange',params)
}

const distributionMessage = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/goods/distributionMessage',params)
}

export default {
	goodsList,
	goodsDetail,
	goodsExchange,
	distributionMessage
}
