<template>
	<div class="goods-detail">
		<w-navTab titleText="详情"></w-navTab>
		<loading-page :loading="loadingPage"></loading-page>
		<div class="container">
			<div class="head-picture"><img :src="goodsDetail.thumb" alt="" /></div>
			<div class="middle">
				<div class="middle-title">{{ goodsDetail.title }}</div>
				<div class="middle-bottom">
					<div class="middle-bottom-left">
						<span>{{ goodsDetail.integral }}</span>
						爱心值兑换
					</div>
					<div class="middle-bottom-right">剩余{{ goodsDetail.stock }}套</div>
				</div>
			</div>
			<div class="bottom-picrure" v-for="vo in goodsDetail.picture"><img :src="vo" alt="" /></div>
			<div class="footer">
				<div class="footer-btn">
					<van-button type="primary" round color="#3377FF" block @click="isShowForm = true">立即兑换</van-button>
				</div>
			</div>
			<van-popup v-model="isShowForm" round position="bottom" closeable close-icon="close"
				:style="{ height: '62%' }">
				<div class="popup">
					<div class="popup-nav">
						<W-Title title="收货信息"></W-Title>
					</div>
					<div class="popup-input"><input type="text" placeholder="请输入您的姓名" v-model="contactName" /></div>
					<div class="popup-input"><input type="number" placeholder="请输入您的联系电话" v-model="contactMobile" />
					</div>
					<div class="popup-info">
						<div class="popup-info-title">配送方式：</div>
						<div class="popup-info-text">{{ distributionMessage.way }}</div>
					</div>
					<div class="popup-info">
						<div class="popup-info-title">联系电话：</div>
						<div class="popup-info-text">{{ distributionMessage.telephone }}</div>
					</div>
					<div class="popup-info">
						<div class="popup-info-title">协会地址：</div>
						<div class="popup-info-text">{{ distributionMessage.address }}</div>
					</div>
					<div class="popup-footer">
						<van-button type="primary" round color="#3377FF" block @click="headPopup">立即兑换</van-button>
					</div>
				</div>
			</van-popup>
		</div>
	</div>
</template>

<script>
	import GoodsApi from '@/api/goods';
	import WTitle from '@/components/Title';
	import VantVendor from '@/vendor/vant';
	import ValidatorVendor from '@/vendor/validator';
	import LoadingPage from '@/components/LoadingPage';
	import UserApi from '@/api/user';
	export default {
		name: 'GoodsDetail',
		data() {
			return {
				isShowForm: false,
				goodsDetail: '',
				contactName: '',
				contactMobile: '',
				loadingPage: true,
				validator: null,
				distributionMessage: ''
			};
		},
		created() {
			this.goodsId = this.$route.params.goodsId;
			this.getGoodsDetail();

			GoodsApi.distributionMessage()
				.then(result => {
					this.distributionMessage = result.data;
				})
				.catch(error => {});

			this.validator = new ValidatorVendor({
				rules: {
					contact_name: {
						required: true
					},
					contact_mobile: {
						required: true,
						mobile: true
					}
				},
				messages: {
					contact_name: {
						required: '请输入联系人姓名'
					},
					contact_mobile: {
						required: '请输入联系人电话',
						mobile: '联系人电话格式错误'
					}
				}
			});
		},
		methods: {
			getGoodsDetail() {
				let params = {
					goods_id: this.goodsId
				};

				GoodsApi.goodsDetail(params)
					.then(result => {
						this.goodsDetail = result.data;
						this.loadingPage = false;
					})
					.catch(error => {
						VantVendor.Toast.fail(error.msg);
					});
			},
			headPopup() {
				let params = {
					goods_id: this.goodsId,
					contact_name: this.contactName,
					contact_mobile: this.contactMobile
				};

				if (!this.validator.checkData(params)) {
					return;
				}

				VantVendor.Toast.loading('兑换中');

				GoodsApi.goodsExchange(params)
					.then(result => {
						VantVendor.Toast.success('兑换成功');
						this.isShowForm = false;
						setTimeout(() => {
							this.$router.go(-1)
						}, 800)
					})
					.catch(error => {
						VantVendor.Toast.fail(error.msg);
					});
			}
		},
		components: {
			WTitle,
			LoadingPage
		}
	};
</script>

<style lang="less" scoped>
	.goods-detail {
		.container {
			padding-bottom: 75px;

			.head-picture {
				width: 375px;
				height: 375px;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}

			.middle {
				padding: 15px 10px;
				background-color: #fff;

				.middle-title {
					font-size: 24px;
					line-height: 28px;
					color: #333333;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				.middle-bottom {
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin-top: 10px;

					.middle-bottom-left {
						font-size: 18px;
						line-height: 28px;
						color: #666;

						span {
							color: #3377ff;
						}
					}

					.middle-bottom-right {
						font-size: 14px;
						line-height: 28px;
						color: #666;
					}
				}
			}

			.bottom-picrure {
				width: 100%;
				height: 100%;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}

			.footer {
				position: fixed;
				width: 100%;
				bottom: 20px;

				.footer-btn {
					padding: 0 10px;
				}
			}

			.popup {
				padding: 0 10px;
		
		.popup-nav {
					padding: 15px 0 10px 0;
					border-bottom: 0.5px solid #f2f2f2;
					margin-bottom: 15px;
				}

				.popup-input {
					margin-top: 10px;
					background: #f2f2f2;
					border-radius: 6px;
					overflow: hidden;
					padding: 10px 0;

					input {
						width: 100%;
						background: #f2f2f2;
						border: 0;
						font-size: 16px;
						line-height: 26px;
						padding-left: 10px;
					}
				}

				.popup-info {
					display: flex;
					justify-content: flex-start;
					margin-top: 10px;

					.popup-info-title {
						width: 85px;
						min-width: 85px;
						font-size: 16px;
						line-height: 28px;
						color: #333;
					}

					.popup-info-text {
						font-size: 16px;
						line-height: 28px;
						color: #666;
					}
				}

				.popup-footer {
					margin: 20px 0;
				}
			}
		}
	}
</style>
